import React from 'react';

function App() {
  return (
    <div className='app'>
      <h1>No results</h1>

      <h2>The service is not available at the moment</h2>

      <img src='/media/images/svg/not-found.svg'  alt='not found'/>
    </div>
  );
}

export default App;
